import { useLocation } from "@reach/router";
import * as React from "react";
import { Spinner } from "react-bootstrap";
// import Footer from '../components/Footer/Footer'
// import Header from "../components/Header/Header";
// import Layout from "../components/Layout/Layout";

const NotFoundPage = () => {
  const [isLoading, setisLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <div className="clearfix" />
      <div className="mainsec">
        {isLoading ? (
          <div className="loadingSec">
            <Spinner animation="border" variant="dark" />{" "}
          </div>
        ) : (
          <div className="loadingSec">
            <p>Page Not Found !</p>
          </div>
        )}
      </div>
    </>
  );
};

export default NotFoundPage;
